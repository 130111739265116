<template>
  <div class="container">
    <iframe class="iframe-pdf" :src="pdfUrl" frameborder="0"></iframe>
    
  </div>
</template>

<script>
  export default {
    
  
    data () {
      return {
        pdfUrl: '',
      }
    },
    created(){
      var that = this;
      that.pdfUrl = location.origin + that.$route.query.pdfUrl;
    },
    methods: {

    }
      
  }
</script>

<style scoped>
  .container{
    width:100%;
    height:100vh;
  }
  .iframe-pdf{
    width:100%;
    height:100%;
  }
</style>